import { GetServerSidePropsContext, GetStaticPropsContext } from "next";
import { getWordPressProps, WordPressTemplate } from "@faustwp/core";
import type { PageProps, Translated } from "@/types";
import { GLOBAL_REVALIDATE } from "@/constants/revalidate";
import { locales } from "@/constants/locales";
import { useEffect } from "react";
import DriftIFrame from "@/components/atoms/DriftIFrame";

const LANGUAGES = [
  "en",
  "fr",
  "de",
  "es",
  "it",
  "ja",
  "ko",
  "nl",
  "pt-br",
  "pt-pt",
  "sv",
  "zh",
];

function adjustPageURLs() {
  const anchors = document.querySelectorAll("a");

  if (!anchors.length) {
    return;
  }

  const replaceTarget = new RegExp("https://faust-cms.vercel.app/blog");
  const replacementValue =
    `${process.env["NEXT_PUBLIC_SITE_URL"]}/blog` ||
    "https://www.dashlane.com/blog";

  anchors.forEach((anchor) => {
    const url = anchor.href;

    if (url.endsWith("/")) {
      anchor.href = url.replace(/\/$/, "");
    }

    if (replaceTarget.test(url)) {
      anchor.href = url.replace(replaceTarget, replacementValue);
    }
  });
}

function isSSR(
  ctx: GetServerSidePropsContext | GetStaticPropsContext
): ctx is GetServerSidePropsContext {
  return (ctx as GetServerSidePropsContext).req !== undefined;
}

function getResolvedUrl(ctx: GetStaticPropsContext) {
  let resolvedUrl: string | null = null;

  const wordPressNodeParams = ctx.params?.["wordpressNode"];
  if (!isSSR(ctx)) {
    if (wordPressNodeParams && Array.isArray(wordPressNodeParams)) {
      resolvedUrl = `/${wordPressNodeParams.join("/")}`;
    } else {
      resolvedUrl = "/";
    }
  } else {
    resolvedUrl = ctx.resolvedUrl;
  }

  let pageNum = null;

  // if the slug starts with one of these, we want pagination to be active
  const paginated = ["blog", "resources"];

  // Remove the page from the url
  if (
    wordPressNodeParams &&
    Array.isArray(wordPressNodeParams) &&
    // @ts-ignore
    paginated.includes(wordPressNodeParams[0]) &&
    // @ts-ignore
    /^[0-9]*$/.test(wordPressNodeParams[wordPressNodeParams.length - 1])
  ) {
    pageNum = wordPressNodeParams.pop();
    resolvedUrl = `/${wordPressNodeParams.join("/")}`;
  }

  return { resolvedUrl, pageNum };
}

function getSeedNodeLanguage(uri: string | undefined) {
  let theLanguage = "";

  if (uri) {
    LANGUAGES.forEach((lang) => {
      if (uri.includes(`/${lang}/`)) {
        theLanguage = lang;
      }
    });
  }

  return theLanguage;
}

export default function Page(props: PageProps) {
  useEffect(() => {
    adjustPageURLs();
  }, []);

  return (
    <>
      <WordPressTemplate {...props} />
      <DriftIFrame />
    </>
  );
}

function addLanguageToUrl(ctx: GetStaticPropsContext) {
  if (
    !ctx.locale ||
    ctx.locale === ctx.defaultLocale ||
    !ctx.params?.["wordpressNode"]
  )
    return ctx;

  // @ts-ignore
  ctx.params.wordpressNode.unshift(ctx.locale);

  return ctx;
}

export async function getStaticProps(ctx: GetStaticPropsContext) {
  const { resolvedUrl: originalResolvedUrl, pageNum } = getResolvedUrl(ctx);
  ctx = addLanguageToUrl(ctx);

  const data = await getWordPressProps({
    ctx,
    revalidate: GLOBAL_REVALIDATE,
    extra: {
      page: pageNum,
    },
  });

  // @ts-ignore error saying props doesn't exist on data
  const seedNode = data?.props?.__SEED_NODE__;
  const seedNodeLang = getSeedNodeLanguage(seedNode?.uri);

  // Don't act on anything but content (skip categories, tags, etc)
  if (!seedNode || !seedNode.isContentNode) {
    return data;
  }

  // If seedNodeLang doesn't match ctx lang, we're at a url like /blog/[french-slug] when we want to be at /[lang]/blog/[french-slug]
  // We should redirect to [lang]/originalResolvedUrl
  if (seedNodeLang && seedNodeLang !== ctx.locale && seedNodeLang !== "en") {
    return {
      //Trigger 301 redirect
      redirect: {
        permanent: false,
        destination: `/${seedNodeLang}${originalResolvedUrl}`,
      },
    };
  }

  const locale: string = seedNode?.language?.locale ?? "en_US";

  if (locale === "en_US" && ctx.locale !== "en") {
    return {
      notFound: true, // triggers 404
    };
  }

  // Check if the resolved url is a translated url.
  // @ts-ignore
  const translatedUrls = seedNode?.translated?.map((node) => node.uri) ?? [];
  // @ts-ignore
  const foundTranslatedUrl = translatedUrls.find(
    (url: string) => url === originalResolvedUrl
  );

  // Check if this is a page and if the slug matches the source language (en)
  if (
    locale !== "en_US" &&
    !foundTranslatedUrl &&
    seedNode?.__typename === "Page"
  ) {
    const english_uri = seedNode?.translated?.find(
      (each: Translated) => each.language.locale === "en_US"
    )?.uri;
    if (english_uri) {
      return {
        //Trigger redirect
        redirect: {
          permanent: false,
          destination: `/${locales[locale]}${english_uri}`,
        },
      };
    }
  }

  return data;
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: "blocking",
  };
}
